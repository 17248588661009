


export const List = [
    {
        icon:require("../../Img/wx.png"),
        title:"微信支付",
        active:true,
        disabled:false,
        state:'wx'
    },{
        icon:require("../../Img/zfb.png"),
        title:"支付宝支付",
        active:false,
        disabled:false,
        state:'zfb'
    },{
        icon:require("../../Img/sy.png"),
        title:"三易校园支付",
        active:false,
        disabled:true,
        state:'sy'
    },
]
